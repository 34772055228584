import './rewards_page.scss'
import React, {useEffect}                        from 'react';
import Equalizer                                 from 'react-equalizer';
import Nav                                       from '../nav.js';
import ReactGA                                   from 'react-ga';
import uuid                                      from 'react-uuid';
import Badge                                     from './badge';
import BadgesData                                from '../../config/badges_data';
import Prize                                     from './prize';
import bird4,{ReactComponent as Bird} from './images/hummingbird.svg'
import { useSpring, animated } from 'react-spring'
import LegalFooter from '../legal_footer/legal_footer';
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
// const trans2 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;
// const trans3 = (x, y) => `translate3d(${x / 30 * -1}px,${y / 30}px,0)`;
// const trans4 = (x, y) => `translate3d(${x / 80}px,${y / 30}px,0)`
export default function RewardsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);

  var currentTheme = 1;
  var topBg = "images/rewards_page/rewarded-top.png";
  var botBg = "images/rewards_page/rewarded-bottom.png";
  var centerBg = "images/rewards_page/rewarded-center.png"
  if (props.settings.theme && props.settings.theme === 2) {
  currentTheme = 2;
  topBg = "images/rewards_page/rewarded-top-2.png";
  botBg = "images/rewards_page/rewarded-bottom-2.png";
  centerBg = "images/rewards_page/rewarded-center-2.png"
  }
  const sectionTopBgStyle = { backgroundImage: 'url(' + topBg + ')' };
  const sectionBotBgStyle = { backgroundImage: 'url(' + botBg + ')' };
  const sectionCenterBgStyle = { backgroundImage: 'url(' + centerBg + ')' };
  ReactGA.event({
    category: 'Rewards',
    action: 'page visited'
  });


  const [settings, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 150, tension: 550, friction: 140 } }))
  return(
    <>
    <Nav settings = {props.settings}/>
   
    <section id="rewards" className={["rewards section section-gap-1  text-center",currentTheme === 1 ? 'section-bg-gi' : 'section-bg-0'].join(' ')} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })} style={sectionCenterBgStyle}>
      <div className="background-image-top" style={sectionTopBgStyle}></div>
      <div className="background-image-bottom"  style={sectionBotBgStyle}></div> 
      <div className="grid-container">
        <div className="grid-x">
          <div className="cell">
            <div className="section-container">
              <h1 className="section-title">Rewards</h1>
              <h2 className="section-subtitle">The further you go, the more you earn.</h2>
              <div className="section-description prizes-description">
              Prizes will be determined by the top achievements and rewarded at the end of the challenge September 2nd, 2022.
                  {/* <br/> Badges/Points earned previous to the Challenge will not count towards eligibility for the prizes. 
                  Points will be used as tie-breakers.
                  <br/>
                  <br/> */}
                  {/* <p>
                  All prizes are below the legal value limit (35 EUR) for third-party gratuities and can be accepted. CD 3002 “Gratuities in dealings with third parties” must be observed, as well as other GB specific regulations where applicable. 
                  </p> */}
                {/* <p> We are going to take a snapshot of your current points and badges at the beginning of our Challenge, 
                    and compare that after two months to see who made the most progress.
                    <br />Leaderboard will be posted regularly via the SPAS Community of Practice channel on Teams.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rewards-prize--container">
        <div className="grid-container">
          <div className="grid-x align-center align-stretch">
            {Object.keys(props.settings.rewards).map((t) => (
              <Prize
                key={uuid()}
                cardTitle={t}
                header={props.settings.rewards[t].name}
                subtitle={props.settings.rewards[t].subtitle}
                color={props.settings.rewards[t].color}
                description={props.settings.rewards[t].product_name}
                image={props.settings.rewards[t].image_url}
                price={props.settings.rewards[t].price}
                claimLink={props.settings.rewards[t].permalink}
                altEmea={props.settings.rewards[t].emea}
              />
            ))}
          </div>
        </div>
      </div>
      <br/>
      <div className='text-center z-99'>*Any of the top 3 winners can opt to get a <a href="https://trailheadstoreamer.com/ProductDetail.aspx?did=34184&pid=312922&logoid=0">hoodie</a> instead.</div>
      <div className="rewards-card--container">
        <div className="grid-container">
          <div className="grid-x align-center align-top">
            {Object.keys(BadgesData).map((t) => (
              <Badge
                key={uuid()}
                cardTitle={t}
                header={BadgesData[t].header}
                badges={BadgesData[t].badges}
                points={BadgesData[t].points}
                image={BadgesData[t].image}
                link={BadgesData[t].link}
              />
            ))}
          </div>
        </div>
      </div>
      <animated.div className={["bird", currentTheme === 1 ? "" : "hidden"].join(' ')} style={{ transform: settings.xy.interpolate(trans1) }}><Bird/></animated.div>
      {/* <animated.div className="cloud1" style={{ transform: settings.xy.interpolate(trans4) }}  /> */}
      {/* <animated.div className="cloud2" style={{ transform: settings.xy.interpolate(trans4) }} /> */}
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
    </section>
    </>
)
};
