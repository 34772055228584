export default {
  name: "cigna-evernorth",
  community: [
    {
      name: "Healthcare User Group",
      description: " ",
      image: "https://trailblazer-challenge.s3.amazonaws.com/resources/community/health.jpg",
      permalink: "https://trailhead.salesforce.com/trailblazer-community/groups/0F9300000001tAhCAI?tab=discussion&amp%3Bsort=LAST_MODIFIED_DATE_DESC&sort=LAST_MODIFIED_DATE_DESC",
    },
    {
      name: "Healthcare Provider Success Community",
      description: "",
      image: "https://trailblazer-challenge.s3.amazonaws.com/resources/community/selecthealth.jpg",
      permalink: "https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000GzgySAC?tab=&amp%3Bsort=LAST_MODIFIED_DATE_DESC",
    },
    {
      name: "Cigna Success Community",
      description: " ",
      image: "https://trailblazer-challenge.s3.amazonaws.com/resources/community/img2.png",
      permalink: "https://trailhead.salesforce.com/trailblazer-community/groups/0F94S000000Gv1kSAC?tab=&sort=LAST_MODIFIED_DATE_DESC",
    },
  ],
  chests: [
    {
      title: "Start Your Journey Here",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/starting_force_com/d6c87e2bd754dddc3116963498852e3a_badge.png",
      isOpened: false,

      trails: [
        {
          name: "What is CRM and How Does it Work?",
          subtitle: "Learn all the key areas of Salesforce CRM.",
          permalink:
            "https://www.bing.com/videos/search?q=salesforce+Sales+Cloud+opportunity+management&ru=%2fvideos%2fsearch%3fq%3dsalesforce%2520Sales%2520Cloud%2520opportunity%2520management%26qs%3dn%26form%3dQBVR%26%3d%2525eManage%2520Your%2520Search%2520History%2525E%26sp%3d-1%26pq%3dsalesforce%2520sales%2520cloud%2520opportunity%2520management%26sc%3d0-45%26sk%3d%26cvid%3d29772D07215948CDAEF52E8AF575580F&view=detail&mid=698823510F15DA90B17F698823510F15DA90B17F&rvsmid=7E91142803C0694CCA3B7E91142803C0694CCA3B&FORM=VDRVRV",
          image_url:
            "https://trailhead.salesforce.com/assets/trailmixes/custom_step_icons/audio-7e6489b295ae741422ac8ec118976e663b0957923bd7c63b092b237f7a8a4071.svg",
          note: " ",
          duration: "",
          score: "",
        },
        {
          name: "Salesforce Customer 360: Quick Look",
          subtitle:
            "Put customers at the center of everything with Salesforce Customer 360.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/salesforce-quick-look-1",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/salesforce-customer-360-quick-look/5e5bdaed9747b36047f9d4c8a884c254_badge.png",
          note: " ",
          duration: "5 mins",
          score: "100",
        },
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: " ",
          duration: "1hr 5mins",
          score: "300",
        },
        {
          name: "Create the Trailblazer App",
          subtitle:
            "In this quick start, you learn how to build a simple app without writing a single line of code.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/quickstart-devzone-app/devzone-app-1",
          image_url:
            "https://trailhead.salesforce.com/assets/trailmixes/custom_step_icons/audio-7e6489b295ae741422ac8ec118976e663b0957923bd7c63b092b237f7a8a4071.svg",
          note: " ",
          duration: "",
          score: "",
        },
        {
          name: "Salesforce Platform Basics",
          subtitle:
            "Get introduced to the platform, navigate use cases, and build custom functionality.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/starting_force_com?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/starting_force_com/d6c87e2bd754dddc3116963498852e3a_badge.png",
          note: " ",
          duration: "",
          score: "",
        },
        {
          name: "Data Modeling",
          subtitle:
            "Give your data structure with objects, fields, and relationships.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/data_modeling?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/data_modeling/c87f1c467561ff36a9bffdebcbc835e8_badge.png",
          note: " ",
          duration: "",
          score: "",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: " ",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Service Cloud Basics",
          subtitle:
            "Supercharge your agents with omni-channel tools and an intelligent service platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/service-cloud-platform-quick-look?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/service-cloud-platform-quick-look/db2713f3bec133c96429ddc96373ed09_badge.png",
          note: " ",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Health Cloud for Payers",
          subtitle:
            "Learn how to deliver fantastic member experiences with Health Cloud for Payers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/health-cloud-for-payers?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/health-cloud-for-payers/bc771178a813d1632d301ff580fd96ed_badge.png",
          note: " ",
          duration: "30 mins",
          score: "300",
        },
        {
          name: "Make Service Cloud Smarter",
          subtitle:
            "Enhance your customer service with Einstein Bots and other artificial intelligence (AI) features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/service_einstein?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/service_einstein/20822c2a610c5998a3374da20265dcff_icon.png",
          note: " ",
          duration: "3 hrs 10 mins",
          score: "1,700",
        },
        {
          name: "Marketing Cloud Basics",
          subtitle:
            "Explore our powerful suite of products and start building your digital marketing strategy.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/mrkt_cloud_basics?trailmix_creator_id=pcameron&trailmix_slug=start-your-journey-here",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/mrkt_cloud_basics/bb76c1487a83ad8836d26c10f03ddfa4_badge.png",
          note: " ",
          duration: "30 mins",
          score: "300",
        },
      ],
      certs: [],
    },
    {
      title: "Analyst ",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "Required",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "Optional",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "Required",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Recommended",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "Required",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "Required",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "Recommended",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "Recommended",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "Recommended",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "Optional",
          duration: "17 hrs",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Recommended",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "Optional",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "Required",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "Recommended",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "Recommended",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "Recommended",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "Recommended",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "Required",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "Required",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "Required",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Recommended",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Recommended",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "Recommended",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Required",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Optional",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "Optional",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Business Analyst",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/businessanalyst",
          note: "Required",
        },
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Recommended",
        },
      ],
    },
    {
      title: "Technology Lead",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "Recommended",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "Recommended",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "Required",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Required",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "Recommended",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "Recommended",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "Recommended",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "Recommended",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "Recommended",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "Recommended",
          duration: "17 hrs",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "Recommended",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Recommended",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Architect Journey: Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management. ",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Architect Journey: Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "Recommended",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "Required",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "Recommended",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "Recommended",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Recommended",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Recommended",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "Recommended",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Required",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Recommended",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "Recommended",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Business Analyst",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/businessanalyst",
          note: "Recommended",
        },
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Required",
        },
        {
          name: "Advanced Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/advancedadministrator",
          note: "Required",
        },
        {
          name: "Platform App Builder",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/platformappbuilder",
          note: "Recommended",
        },
      ],
    },
    {
      title: "Developer",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "Required",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "Optional",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "Required",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Required",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "Recommended",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "Recommended",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "Recommended",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "Required",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "Required",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "Required",
          duration: "17 hrs",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "Required",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Recommended",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Architect Journey: Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management. ",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Architect Journey: Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "Recommended",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "Recommended",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "Required",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "Recommended",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "Optional",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "Required",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "Recommended",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "Required",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "Required",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "Optional",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Recommended",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "Optional",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Recommended",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "Recommended",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Required",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "Required",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Required",
        },
        {
          name: "Platform App Builder",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/platformappbuilder",
          note: "Required",
        },
        {
          name: "Platform App Dev I",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/prepare-for-your-salesforce-platform-developer-i-credential",
          note: "Required",
        },
      ],
    },
    {
      title: "Admin",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "Required",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "Recommended",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "Required",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Required",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "Required",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "Required",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "Required",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "Recommended",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "Recommended",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "Optional",
          duration: "17 hrs",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Required",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Architect Journey: Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management. ",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Architect Journey: Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "recquired",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "Recommended",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "Recommended",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "Optional",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "Required",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "Recommended",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "Required",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "Required",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "Required",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "Required",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "Required",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "Recommended",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Recommended",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Recommended",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "Recommended",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Required",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "Recommended",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Business Analyst",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/businessanalyst",
          note: "Recommended",
        },
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Required",
        },
        {
          name: "Advanced Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/advancedadministrator",
          note: "Required",
        },
        {
          name: "Platform App Builder",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/platformappbuilder",
          note: "Recommended",
        },
      ],
    },
    {
      title: "Architect",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "Required",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Recommended",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "Required",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "Recommended",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "Recommended",
          duration: "17 hrs",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Required",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Architect Journey: Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management. ",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Architect Journey: Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "Optional",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "Recommended",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "Optional",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "Required",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "Recommended",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Recommended",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Recommended",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Recommended",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Required",
        },
        {
          name: "Advanced Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/advancedadministrator",
          note: "Recommended",
        },
        {
          name: "Platform App Builder",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/platformappbuilder",
          note: "Required",
        },
        {
          name: "Platform App Dev I",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/prepare-for-your-salesforce-platform-developer-i-credential",
          note: "Required",
        },
        {
          name: "System Architect",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/systemarchitect",
          note: "Recommended",
        },
        {
          name: "Application Architect",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/applicationarchitect",
          note: "Recommended",
        },
      ],
    },
    {
      title: "Business Owner",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "Required",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Recommended",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "Recommended",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "Recommended",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "Recommended",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "Optional",
          duration: "17 hrs",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Required",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Architect Journey: Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management. ",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Architect Journey: Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "Optional",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "Recommended",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "Optional",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "Required",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "Required",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "Required",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "Recommended",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Recommended",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Recommended",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "Recommended",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Required",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "Recommended",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Business Analyst",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/businessanalyst",
          note: "Required",
        },
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Required",
        },
        {
          name: "Advanced Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/advancedadministrator",
          note: "Recommended",
        },
      ],
    },

    {
      title: "Technical Product Owner",
      subtitle: "",
      image:
        "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Required",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "Required",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "Recommended",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "Recommended",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "Optional",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "Optional",
          duration: "17 hrs ",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Required",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Architect Journey: Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management. ",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Architect Journey: Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Required",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "Optional",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "Recommended",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "Recommended",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "Recommended",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "Required",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Recommended",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "Recommended",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Recommended",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Required",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Recommended",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "Recommended",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Business Analyst",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/businessanalyst",
          note: "Recommended",
        },
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Recommended",
        },
      ],
    },
    {
      title: "Business Product Owner",
      subtitle: "",
      image:
        "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
      isOpened: false,
      trails: [
        {
          name: "Salesforce User Basics",
          subtitle:
            "Get started with Salesforce and learn how to make it work for your bottom line.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lex_salesforce_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_salesforce_basics/67daab1ff0f87c9ee78c3e8681193b43_badge.png",
          note: "Required",
          duration: "1 hr 5 mins",
          score: "300",
        },
        {
          name: "Sales Cloud for Sales Leaders",
          subtitle:
            "Best practices for Sales Leaders using Sales Cloud to drive efficiency, productivity, and revenue!",
          permalink:
            "https://trailhead.salesforce.com/en/users/strailhead/trailmixes/sales-cloud-for-sales-leaders",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-quick-look-1/1d68da5985c63184b4b5d0bbfe7462d4_badge.png",
          note: "Recommended",
          duration: "12 hrs 5 mins",
          score: "8,600",
        },
        {
          name: "Customer Service Leaders",
          subtitle: "Best Practices for delivering superior Customer Service",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/service-cloud-for-leaders-agents",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Recommended",
          duration: "8 hrs",
          score: "5,100",
        },
        {
          name: "Deliver Great Patient Care with Health Cloud",
          subtitle:
            "Learn how Health Cloud helps you deliver collaborative, connected support for patients and caregivers.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/deliver-great-patient-care-with-health-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/deliver-great-patient-care-with-health-cloud/adf81afc1c0088a6dbc94afa9464989f_icon.png",
          note: "",
          duration: "13 hrs 15 mins",
          score: "12,000",
        },
        {
          name: "Get Started with Lightning Experience",
          subtitle:
            "Start your journey to becoming a new Salesforce Admin with Lightning Experience.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_admin_implementation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_admin_implementation/cf5381a5c3c5c0a435d4aebfc668745a_icon.png",
          note: "Recommended",
          duration: "8 hrs 55 mins",
          score: "7,000",
        },
        {
          name: "Protect Your Data in Salesforce",
          subtitle:
            "Secure your Salesforce org by controlling login and data access for users.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/projects/protect-your-data-in-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/projects/protect-your-data-in-salesforce/004ad71c614a016eda94ea2e864a1258_badge.png",
          note: "Recommended",
          duration: "1 hr 5 mins",
          score: "500",
        },
        {
          name: "Prepare for Salesforce Releases",
          subtitle:
            "Learn the latest Salesforce release features and craft your release strategy",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sf_release_prep/a81ce43a21d1592c8d8b8e146c3a4db1_icon.png",
          note: "Recommended",
          duration: "9 hrs",
          score: "4,000",
        },
        {
          name: "Admin Beginner",
          subtitle:
            "Start your #AwesomeAdmin journey by learning the basics of customizing Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_beginner/2e79e8c47998058692d9341db7cd4a83_icon.png",
          note: "Recommended",
          duration: "9 hrs 20 mins",
          score: "9,500",
        },
        {
          name: "Admin Intermediate",
          subtitle:
            "Supercharge your skills and apps by using point-and-click tools to solve common business needs.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_intermediate/346e918e91ff20e66c50855e73be4fef_icon.png",
          note: "",
          duration: "11 hrs 35 mins",
          score: "10,100",
        },
        {
          name: "Admin Advanced",
          subtitle:
            "Reach all star admin status by tackling more advanced Salesforce features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_admin_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_admin_advanced/2c5e54256587338cb6207b75ade31b9a_icon.png",
          note: "",
          duration: "3 hrs 55 mins ",
          score: "4,000",
        },
        {
          name: "Develop for Lightning Experience",
          subtitle:
            "Build apps fast for Lightning Experience with Visualforce, components, and new design resources.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/lex_dev",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/lex_dev/43648b24a01a6d3de06617fa94d43e6a_icon.png",
          note: "",
          duration: "14 hrs 5 mins",
          score: "8,700",
        },
        {
          name: "Developer Beginner",
          subtitle:
            "Leverage your existing development skills to build apps fast on the Salesforce Platform.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_beginner",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_beginner/39ed2e90cae0e0428a8ea1a110425370_icon.png",
          note: "",
          duration: "18 hrs",
          score: "20,400",
        },
        {
          name: "Developer Intermediate",
          subtitle:
            "Take your apps to the next level with powerful integration and mobile tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_intermediate",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_intermediate/947613da151e26c2328f795fb6142889_icon.png",
          note: "",
          duration: "17 hrs",
          score: "13,700",
        },
        {
          name: "Developer Advanced",
          subtitle:
            "Unlock the power of the platform and develop enterprise-class skills to advance your developer career.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/force_com_dev_advanced",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/force_com_dev_advanced/1e1e9357505a768cf56d4bb1cbe0b65d_icon.png",
          note: "",
          duration: "4 hrs 30 mins",
          score: "2,700",
        },
        {
          name: "Cert Prep: Salesforce User Experience Designer",
          subtitle:
            "Use scenarios and flashcards to study for the User Experience Designer certification exam.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/ux-designer-certification-prep",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/ux-designer-certification-prep/eb7db999394140ef47540d369b37127e_badge.png",
          note: "Required",
          duration: "1 hr 10 mins",
          score: "700",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Architect Journey: Data Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Data Architecture and Management. ",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-data-architecture-and-management",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "24 hrs",
          score: "9,100",
        },
        {
          name: "Architect Journey: Development Lifecycle and Deployment",
          subtitle:
            "Ready for your Development Lifecycle and Deployment credential? Check out this trail mix that has been curated to help you get exam ready!",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-dev-lifecycle-and-deployment",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "21 hrs 25 mins",
          score: "8,500",
        },
        {
          name: "Learn Admin Fundamentals in Marketing Cloud",
          subtitle: "Discover how to successfully administer Marketing Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/administer-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/administer-marketing-cloud/c2b1c8f7bc9294cbe21cec9d30fa7973_icon.png",
          note: "Optional",
          duration: "4 hrs 30 mins",
          score: "1,500",
        },
        {
          name: "Advance Your Marketing Strategy",
          subtitle:
            "Go beyond the basics of Marketing Cloud with email personalization, reporting, and design.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/advance-your-marketing-strategy",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/advance-your-marketing-strategy/2cb7134262065c4e50b8da63e5aab4e6_icon.png",
          note: "",
          duration: "5 hrs 25 mins",
          score: "3,100",
        },
        {
          name: "Learn Developer Fundamentals in Marketing Cloud",
          subtitle:
            "Expand your developer skills to unlock the power of Marketing Cloud tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/develop-for-marketing-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/develop-for-marketing-cloud/834b2ba1bac2672e538ebb32a7a0a221_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "2,200",
        },
        {
          name: "Increase Sales Efficiency with Territory Management",
          subtitle:
            "Help your sales team keep track of who's assigned to each account and opportunity.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/increase-efficiency-territory-management",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/increase-efficiency-territory-management/8b29b34dcf9783a96ba76e2f56dd755c_icon.png",
          note: "",
          duration: "1 hr 20 mins",
          score: "1,200",
        },
        {
          name: "Optimize Sales Features for Lightning Experience",
          subtitle:
            "Help your sales teams improve selling relationships and close more deals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/sales_admin",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/sales_admin/b3693f0733e8bcdf57bcbd124d626927_icon.png",
          note: "",
          duration: "4 hrs 15 mins",
          score: "3,800",
        },
        {
          name: "Empower Your Salesforce Users",
          subtitle: "Learn effective strategies for driving end user adoption.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/empower-your-salesforce-users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/empower-your-salesforce-users/1fc0bce41e2ca5704c0e60ca9e6f3c84_icon.png",
          note: "",
          duration: "2 hrs 45 mins",
          score: "2700",
        },
        {
          name: "Become an Integration Champion",
          subtitle:
            "Learn the MuleSoft methodology for integration and business reinvention.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/mulesoft-integration-trailblazer",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/mulesoft-integration-trailblazer/44877c72b0f96eaf3c22569492f6000e_icon.png",
          note: "Recommended",
          duration: "1 hr 40 mins",
          score: "1,000",
        },
        {
          name: "Learn Admin Essentials in Lightning Experience",
          subtitle:
            "Master the fundamentals of administering a Salesforce org.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/learn-admin-essentials",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/learn-admin-essentials/c6cef473d6e07f0dd0d34be10deabc00_icon.png",
          note: "",
          duration: "8 hrs 45 mins",
          score: "4,700",
        },
        {
          name: "Get Started with Sales Cloud",
          subtitle:
            "Use Sales Cloud's features and tools to achieve your business goals.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-with-sales-cloud",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-with-sales-cloud/c34a97bb6b958970c13f167116d55530_icon.png",
          note: "",
          duration: "50 mins",
          score: "400",
        },
        {
          name: "Learn CRM Fundamentals for Lightning Experience",
          subtitle:
            "Understand Salesforce and learn how to navigate, customize, and manage basic CRM features.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/crm-essentials-lightning-experience",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/crm-essentials-lightning-experience/6162c74a62d311ed3739c3bfb059d6cc_icon.png",
          note: "",
          duration: "5 hrs 25 mins",
          score: "5,400",
        },
        {
          name: "Get Started as a Salesforce Business Analyst",
          subtitle:
            "Explore the skills that make Salesforce business analysts effective business partners.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/trails/get-started-as-a-salesforce-business-analyst?trailmix_creator_id=strailhead&trailmix_slug=build-your-business-analyst-career-on-salesforce",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/get-started-as-a-salesforce-business-analyst/5f525390053c53a93c73d67872ae35f8_icon.png",
          note: "Required",
          duration: "3 hrs 5 mins",
          score: "1,800",
        },
        {
          name: "Build Your Admin Career on Salesforce",
          subtitle:
            "Follow this well-trodden path to a successful #awesomeadmin career.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-admin-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "Optional",
          duration: "36 hrs 25 mins ",
          score: "28,900",
        },
        {
          name: "Build Your Developer Career on Salesforce",
          subtitle:
            "New to Salesforce development? Here's a quick tour of how you can quickly build apps and integrations with Salesforce.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/build-your-developer-career-on-salesforce",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "9 hrs 25 mins",
          score: "6,300",
        },
        {
          name: "Programmatic Architecture",
          subtitle:
            "Grow your architect skills and expertise in the area of Programmatic Architecture.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-programmatic-architecture",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "49 hrs 76 mins",
          score: "21,000",
        },
        {
          name: "Application Design",
          subtitle:
            "Grow your architect skills and expertise in the area of Application Design.",
          permalink:
            "https://trailhead.salesforce.com/users/strailhead/trailmixes/architect-application-design",
          image_url: "https://trailblazer.me/profilephoto/7291I0000019lbG/F",
          note: "",
          duration: "31 hrs 33 mins",
          score: "26,600",
        },
        {
          name: "CRM Analytics Basics",
          subtitle:
            "Uncover new insights from your business data using CRM Analytic.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/wave_analytics_basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: "Required",
          duration: "50 mins",
          score: "600",
        },
        {
          name: "Sales Cloud Basics",
          subtitle:
            "Grow your business, boost productivity, and make smart decisions with Sales Cloud.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/sales-cloud-platform-quick-look",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: "Required",
          duration: "40 mins",
          score: "400",
        },
        {
          name: "Digital Transformation",
          subtitle:
            "Explore trends causing companies to transform their products and customer relationships",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/digital-transformation",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/digital-transformation/0c7aaeecfa33d54dacf7467d37789b1b_badge.png",
          note: "Required",
          duration: "20 mins",
          score: "200",
        },
        {
          name: "Chatter for Lightning Experience",
          subtitle:
            "Learn how to use Chatter, update your profile, and start collaborating.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/chatter_basics_for_users",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/chatter_basics_for_users/efb3a45396a76302451d2fa0c54f4a7a_badge.png",
          note: "Required",
          duration: "55 mins",
          score: "1,600",
        },
        {
          name: "Reports & Dashboards: Quick Look",
          subtitle: "See the big picture and explore key business data.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/reports-dashboards-quick-look",
          image_url:
            "https://developer.salesforce.com/resource/images/trailhead/badges/modules/trailhead_module_reports-dashboards-quick-look.png",
          note: "Required",
          duration: "10 mins",
          score: "100",
        },
        {
          name: "Flow Basics",
          subtitle:
            "Explore Flow Builder and learn when to use flows to automate your business processes.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/flow-basics",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/flow-basics/c2d1e1b78bb73f734b6f668e6f9428de_badge.png",
          note: "Recommended",
          duration: "40 mins",
          score: "800",
        },
        {
          name: "Lightning App Builder",
          subtitle:
            "Build custom pages for Lightning Experience quickly with point-and-click tools.",
          permalink:
            "https://trailhead.salesforce.com/content/learn/modules/lightning_app_builder",
          image_url:
            "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lightning_app_builder/e74ff600f7c04071af20c5d1e9686042_badge.png",
          note: "Recommended",
          duration: "1 hr 35 mins",
          score: "2,100",
        },
      ],
      certs: [
        {
          name: "Business Analyst",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/businessanalyst",
          note: "Recommended",
        },
        {
          name: "Admin",
          permalink:
            "https://trailhead.salesforce.com/en/credentials/administrator",
          note: "Recommended",
        },
      ],
    },
    {
      title: "Products",
      subtitle: "",
      image: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_mobile_exploration/8836ef0a67a1d4c0eb2cc8b156d398c4_badge.png",
      isOpened: false,
      trails: [
        {
          name: "Health Cloud",
          subtitle: "Learn how Health Cloud helps you deliver collaborative, connected support for members, providers and caregivers.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/health-cloud",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/health-cloud-for-payers/bc771178a813d1632d301ff580fd96ed_badge.png",
          note: " ",
          duration: " ",
          score: "10,000",
        },
				{
          name: "Service Cloud",
          subtitle: "Learn all the key capabilities of Service Cloud.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/service-cloud",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/servicecloud-quick-look/fdb8732b76152b095e1e55b8c7e617bd_badge.png",
          note: " ",
          duration: " ",
          score: "19,000 ",
        },
				{
          name: "Sales Cloud",
          subtitle: "Learn key capabilities of Sales Cloud.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/sales-cloud",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sales-cloud-platform-quick-look/b91b2499486a772a4eee8ce55aa32b57_badge.png",
          note: " ",
          duration: " ",
          score: "7,100",
        },
				{
          name: "Experience Cloud",
          subtitle: "Learn more about Experience Cloud to connect your members and providers.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/experience-cloud",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/community_cloud_basics/5d36798f5f1641c43bf4ec0ff023e60e_badge.png",
          note: " ",
          duration: " ",
          score: "4,900",
        },
				{
          name: "Getting Started with CRM Analytics",
          subtitle: "Follow this series of trails and videos to get started with CRM Analytics.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/crm-analytics-getting-started",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/wave_analytics_basics/ec05052df4f2267da12ca869c2f03487_badge.png",
          note: " ",
          duration: " ",
          score: "1,800",
        },
				{
          name: "Field Service Lightning (FSL)",
          subtitle: "Learn everything you need to know about FSL.",
          permalink: "",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/field_service_basics/6dad992a919124cc96f6749400a6244b_badge.png",
          note: " ",
          duration: " ",
          score: "7,900",
        },
				{
          name: "Marketing Cloud - Level 1",
          subtitle: "Start your Marketing Cloud learning journey here.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/marketing-cloud-level-1",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/marketing-cloud-contact-management/e56ceb94a7422528d5be990ca7f042d3_badge.png",
          note: " ",
          duration: " ",
          score: "8,300",
        },
				{
          name: "Marketing Cloud - Level 2",
          subtitle: "Take your Marketing Cloud learning journey to the next level.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/marketing-cloud-level-2",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/personalized-email-marketing/fba8b7eee5098d5bc9e65ceb9e22df80_badge.png",
          note: " ",
          duration: " ",
          score: "9,600",
        },
				{
          name: "Mulesoft",
          subtitle: "Learn about the Mulesoft methodology and integration practices.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/mulesoft",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/api-led-integration-for-business-reinvention/6c0865735612fdc1863ba787dda6d4c2_badge.png",
          note: " ",
          duration: " ",
          score: "1,400",
        },
				{
          name: "SFI & OmniStudio",
          subtitle: "Learn OmniStudio and build guided experiences.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/sfi-omni-studio",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/trails/build-guided-experiences-with-omnistudio/cdcbe53df9e54255eaf8d2de4bbe6f2c_icon.png",
          note: " ",
          duration: " ",
          score: "3,500",
        },
				{
          name: "Advanced Monitoring",
          subtitle: "Discover insights into your Salesforce org with these powerful monitoring features.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/advanced-monitoring",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/event_monitoring/f1d010fe80ea0d025c902a56b8ff56bc_badge.png",
          note: " ",
          duration: " ",
          score: "2,200",
        }
      ],
      certs: [],
    },
    {
      title: "Productivity",
      subtitle: "",
      image: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/service-cloud-agent-productivity/0d3390a149963e8e4c32a2b02bac7949_badge.png",
      isOpened: false,
      trails: [
        {
          name: "Service Cloud Productivity",
          subtitle: "Learn about all the top Service Cloud productivity capabilities.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/service-cloud-productivity",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/service-cloud-agent-productivity/0d3390a149963e8e4c32a2b02bac7949_badge.png",
          note: " ",
          duration: " ",
          score: "8,500",
        },
				{
          name: "Make Service Cloud Smarter",
          subtitle: "Enhance your customer service with Einstein Bots and other artificial intelligence (AI) features.",
          permalink: "https://trailhead.salesforce.com/content/learn/trails/service_einstein?trailmix_creator_id=pcameron&trailmix_slug=service-cloud-productivity",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/einstein-for-service-rollout-strategies/0efafdf1491f5738824df75178c206fb_badge.png",
          note: " ",
          duration: "3hrs 10mins",
          score: "1,700",
        },
				{
          name: "Platform Productivity",
          subtitle: "Learn about the top platform features that improve productivity include console apps, quick actions, buttons, links and more.",
          permalink: "https://trailhead.salesforce.com/users/pcameron/trailmixes/platform-productivity2",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/lex_customization/d764204d87b3dc1833baa96fccb2dbf2_badge.png",
          note: " ",
          duration: " ",
          score: "8,400",
        },
				{
          name: "Prepare for Salesforce Releases",
          subtitle: "Learn the latest Salesforce release features and craft your release strategy.",
          permalink: "https://trailhead.salesforce.com/content/learn/trails/sf_release_prep",
          image_url: "https://res.cloudinary.com/hy4kyit2a/f_auto,fl_lossy,q_70/learn/modules/sf_releases/92c26e36df888c1aae727e17365eae5d_badge.png",
          note: " ",
          duration: "9hrs",
          score: "4,000",
        },
      ],
      certs: [],
    },
  ],
};
