import React from 'react';
import './trails.scss';
import TrailList from './trail_list'

export default function Trails(props){
  // var trailsBg = "images/06_trails/trails-map.png";
  // const sectionBgStyle = { backgroundImage: 'url('+ trailsBg +')' };
  const corpColorBgStyle = { backgroundColor: props.primaryColor };
  const corpColorStyle = { color: props.primaryColor };
  function explore(){
    props.setChestsLoading(true);
    props.goToSlide(2);
  }
  return(
    <section id="trails-section" className={[props.currentSlide > 2 ? 'trails section section-bg-1 text-center visible' : 'trails section section-bg-1 text-center hidden', props.currentSlide === 4 ? 'active' : ''].join(' ')}>
      <div className="grid-container fluid">
        <div className="grid-x align-center">
          <div className="cell small-12 medium-10">
            <div className="section-container section-gap-2">
              <div className="section-tag">02/02</div>
              <h2 className="section-title"><em style={corpColorStyle}>{props.currentChest.title}</em></h2>
              <h3 className="section-subtitle">Blaze your trail.</h3>
              <div className="section-description">Explore these modules to skill up and earn badges!</div>
              <div className="trails-card--container">

                <div className="trails-card--top">Start</div>
                  <TrailList
                  trailData = {props.trailData}
                  certData = {props.certData}
                  trailsLoading = {props.trailsLoading}
                  currentChest = {props.currentChest}
                  trackEvent={props.trackEvent}
                  />
                <div className="trails-card--bottom">Finish</div>
              </div>
              <div className="trails-button">
                <ul>
                <li><a className="i1" href="#" onClick={explore}>Explore more trails</a></li>
                <li><a className="i2" href="#" style={corpColorBgStyle} onClick={props.nextSlide}>Completed!</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};
