import 'foundation-sites/dist/css/foundation.min.css';
import './App.scss';
import companySettings from './config/settings';
import rolesAndTrails from './config/trail_data';
import menu from './config/main_nav'
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import Quest from './components/quest_page';
import SfPlusPage from './components/sfplus_page/sfplus_page';
import RewardsPage from './components/rewards_page/rewards_page';
import CommunityPage from './components/community_page/community_page';
import TeamPage from './components/team_page/team_page';
import Loading         from './components/loading/loading';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// import sample_company_data from './config/sample_company_data';

// const trackingId = 'UA-167587773-11';
// ReactGA.initialize(trackingId);

export default function App() {
  const [height, setHeight] = useState(window.innerHeight);
  const [settings, setSettings] = useState({});
  const [trailData, setTrailData] = useState(rolesAndTrails);
  const [loading, setLoading] = useState(true)
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [consentOpen, setConsentOpen] = useState(true);
  const [passwordEntered, setPasswordEntered] = useState(false);
  // const [formModalUrl, setFormModalUrl] = useState('');
  function closeFormModal() {
    setFormModalOpen(false);
  }
  function trackEvent(s) {
    // ReactGA.event({
    //   category: 'Trailhead Challenge',
    //   action: s
    // });
  }

  function setCompanyData(data) {
    if (data) {
      setSettings(data);
      setLoading(false);
    }
  };


  useEffect(() => {
    // let host = window.location.host.split('.')[0];
    // if (host === 'localhost:3000') {
    //   setCompanyData(sample_company_data);
    
    // } else {
    let fetch_url = companySettings.name !== ''
      ? 'https://s-tet-trailblazers-cms.herokuapp.com/company.json?id=' + companySettings.name
      : 'https://s-tet-trailblazers-cms.herokuapp.com/company.json?id=default'
    const fetchCompanyData = async () => {
      await axios(fetch_url
      ).then((response) => {
        setCompanyData(response.data);
        console.log(response);
      }).catch((e) => {
        console.log(e);
      });
    };
    fetchCompanyData();
    // }
  }, []);

  if (loading === true) {
    return <Loading/>
  } else {
    return (
    <Router>
      <div id="app" className="app">
        <Switch>
          <Route
            exact path={menu.trails.link}
            render={(props) =>
              <Quest {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
                passwordEntered={passwordEntered}
                setPasswordEntered={setPasswordEntered}
                setSettings={setSettings}
                trackEvent={trackEvent}
                trailData={trailData}
                setTrailData={setTrailData}
              />
            }
          />
          <Route
            path={menu.rewards.link}
            render={(props) =>
              <RewardsPage {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
                trackEvent={trackEvent}
                height={height}
                formModalOpen={formModalOpen}
                setFormModalOpen={setFormModalOpen}
                closeFormModal={closeFormModal}
              />
            }
          />
          <Route
            path={menu.sfplus.link}
            render={(props) =>
              <SfPlusPage {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
                trackEvent={trackEvent}
              />
            }
          />

          <Route
            path={menu.connect.link}
            render={(props) =>
              <CommunityPage {...props}
              consentOpen={consentOpen}
              setConsentOpen={setConsentOpen}
                settings={settings}
                trackEvent={trackEvent}
                trailData={trailData}
              />
            }
          />
          <Route
            path={menu.team.link}
            render={(props) =>
              <TeamPage {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
                trackEvent={trackEvent}
              />
            }
          />
        </Switch>
      </div>
    </Router>
  );
}
}