import './community_page.scss'
import React, { useEffect } from 'react';
import Equalizer from 'react-equalizer';
import Nav from '../nav.js';
import ReactGA from 'react-ga';
import uuid from 'react-uuid';
import ResourceCard from './resource_card';
import LegalFooter from '../legal_footer/legal_footer';
import { useSpring, animated } from 'react-spring'
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`

export default function RewardsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);
  var currentTheme = 1;
  var topBg = "images/community_page/connect-bg-top.png";
  var botBg = "images/community_page/connect-bg-bottom.png";
  var midBg = "images/community_page/connect-bg-middle.png"
  if (props.settings.theme && props.settings.theme === 2) {
    currentTheme = 2;
    topBg = "images/community_page/connect-bg-top-2.png";
    botBg = "images/community_page/connect-bg-bottom-2.png";
   midBg = "images/community_page/connect-bg-middle-2.png"
  }
  const sectionTopBgStyle = { backgroundImage: 'url(' + topBg + ')' };
  const sectionBotBgStyle = { backgroundImage: 'url(' + botBg + ')' };

  ReactGA.event({
    category: 'Team',
    action: 'page visited'
  });
  const [settings, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 150, tension: 550, friction: 140 } }))
  return (
    <>
      <Nav settings={props.settings} />
      <section id="community-page" className={["material full-height section section-gap-1 section-bg-g1 text-center", currentTheme === 1 ? "section-bg-g1" : "section-bg-white"].join(' ')} >
        <div className="background-image-top" style={sectionTopBgStyle}></div>
        <div className="background-image-bottom"  style={sectionBotBgStyle}></div> 
        <div className="container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <div className="grid-container" >
            <div className="grid-x">
              <div className="cell">
                <div className="section-container relative">
                  <h1 className="section-title">Connect with Trailblazers.</h1>
                  {/* <h2 className="section-subtitle">Explore relevant community to help you succeed on your Trailhead Journey.</h2> */}
                  <div className="section-description">Join your peers blazing new trails with Salesforce. Expand your professional skills, ask questions on key topics, collaborate, network and get inspired!</div>
                </div>
              </div>
            </div>
          </div>
          <div className="material-card--container relative">
            <div className="grid-container">
              <Equalizer property="maxHeight" className="grid-x align-center align-top">
                {Object.keys(props.settings.resources).map((t) => {
                  if (props.settings.resources[t].doc_type === "community")
                  return <ResourceCard
                    key={uuid()}
                    cardTitle={t}
                    name={props.settings.resources[t].name}
                    subtitle={props.settings.resources[t].subtitle}
                    permalink={props.settings.resources[t].permalink}
                    image={props.settings.resources[t].image_url}
                    trackEvent={props.trackEvent}
                  />
                })}            
              </Equalizer>
            </div>
          </div>
          <br/>
          <br/>
          <h1 className="text-center">Success Team</h1>
          <div className="material-card--container relative">
            <div className="grid-container">
              <Equalizer property="maxHeight" className="grid-x align-center align-top">
                {Object.keys(props.trailData.community).map((t) => {
                  return <ResourceCard
                    key={uuid()}
                    cardTitle={t}
                    name={props.trailData.community[t].name}
                    subtitle={props.trailData.community[t].description}
                    permalink={props.trailData.community[t].permalink}
                    image={props.trailData.community[t].image}
                    trackEvent={props.trackEvent}
                  />
                })}            
              </Equalizer>
            </div>
          </div>
          <br/>
          {/* <div className="grid-container" >
            <div className="grid-x">
              <div className="cell">
                <div className="section-container relative">
                  <h1 className="section-title">Community for Success</h1>
                  <h2 className="section-subtitle">Explore relevant community to help you succeed on your Trailhead Journey.</h2>
                  <div className="section-description">Questions or comments? Feel free to reach out to us.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="material-card--container relative">
            <div className="grid-container">
              <div className="grid-x align-center align-top">
                {Object.keys(props.settings.resources).map((t) => (
                  <ResourceCard
                    key={uuid()}
                    cardTitle={t}
                    name={props.settings.resources[t].name}
                    subtitle={props.settings.resources[t].subtitle}
                    permalink={props.settings.resources[t].permalink}
                    image={props.settings.resources[t].image}
                    trackEvent={props.trackEvent}
                  />
                ))}
              </div>
            </div>
          </div> */}
        </div>
        <br/>
        <br/>
          {/* <animated.div className={["cloud1", currentTheme === 1 ? "" : "hidden"].join(' ')}   /> */}
          {/* <animated.div className={["cloud2", currentTheme === 1 ? "" : "hidden"].join(' ')} /> */}
          {/* <animated.div className="cloud3" style={{ transform: settings.xy.interpolate(trans3) }} /> */}
          {/* <animated.div className={["bird1 main-scene--level-bottom", currentTheme === 1 ? "" : "hidden"].join(' ')} style={{ transform: settings.xy.interpolate(trans1) }} /> */}
          {/* <animated.div className="bird2 main-scene--level-top" style={{ transform: settings.xy.interpolate(trans2) }} /> */}
          <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
      </section>
    </>
  )
};
