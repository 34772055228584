import React from 'react';
export default function Cert(props) {
  return (
    <div className="cell small-12 medium-12 large-4 xlarge-3">
    <a target="_blank" rel="noreferrer noopener" href={props.certData.permalink} className="card  no-underline">
    <div className="pad-20">
      <h2 className="h4 card__header equal text-center no-underline" >{props.certData.name}</h2>
      <div className="small text-center no-underline">{props.certData.note}</div>
    </div>
    </a>
    </div>
  )
};
