import React, {useEffect} from 'react';
import './pass_phrase.scss'
export default function PassPhrase(props){
  useEffect(() => {
    if (props.currentSlide === 0) {
      document.getElementById('name-field').focus();
    } else {
      document.getElementById('name-field').value="cignatrailblazer"
    }
    return () => {};
  });
  function handleKeyDown (e) {
    if (props.currentSlide === 0 && props.name.value.toLowerCase() === 'cignatrailblazer' && e.key === 'Enter') {
      props.setPasswordEntered(true);
      props.nextSlide();
    }
    if (props.name.value === '<' && e.key === '3'){
      props.setPasswordEntered(true);
      props.nextSlide();
    }
  }
  const corpColorBgStyle = { backgroundColor: props.primaryColor };
  const corpColorStyle = { color: props.primaryColor };

  return(
    <section id="name-section" className={['name section section-bg-gi text-center visible', props.currentSlide === 0 ? 'active' : '', 'element'].join(' ')} >
      <div className="grid-container">
        <div className="grid-x align-center align-middle full-height pt80 pb80">
          <div className="cell">
            <div className="section-container section-gap-2">
              <h2 className="section-title"><span><em >Ready to blaze new trails? <br/> Please enter password</em></span></h2>
              <div className="name-form">
                <input style={corpColorStyle} type="text" name="name" id="name-field" autoComplete="off" onKeyDown={handleKeyDown} {...props.name}/>
                <button className="main-button" style={corpColorBgStyle}id="btn-continue-step-2" type="button" disabled={props.name.value.toLowerCase() !== 'cignatrailblazer' || props.currentSlide !== 0} onClick={props.currentSlide === 0 ? props.nextSlide : () => null }>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};
